<template>
  <div>
    <div class="row">
      <div
        class="col-12 bg-light border border-dark border-padding"
        style="margin-bottom:3px;"
      >
        <table class="table-sm table table-border m-0">
          <tbody>
            <tr>
              <td style="font-weight: bold">Type:</td>
              <td style="font-weight: bold; color:#0e5499">
                <b v-if="data.type != null">{{ data.type.name }}</b>
              </td>
              <td style="font-weight: bold">Payment Plan:</td>
              <td style="font-weight: bold; color:#0e5499">
                <b v-if="data.payment_plan != null">{{
                  data.payment_plan.name
                }}</b>
              </td>
              <td style="font-weight: bold">Payment Option:</td>
              <td style="font-weight: bold; color:#0e5499">
                <b v-if="data.payment_option != null">{{
                  data.payment_option.name
                }}</b>
              </td>
            </tr>
            <tr>
              <td style="font-weight: bold">Start On:</td>
              <td style="font-weight: bold; color:#0e5499">
                <b v-if="data.startOn != null">{{
                  formatDate(data.startOn.substr(0, 10))
                }}</b>
              </td>
              <td style="font-weight: bold">Fee:</td>
              <td style="font-weight: bold; color:#0e5499">${{ data.fee }}</td>
              <td class="bt" style="font-weight: bold;">
                Contact Date:
              </td>
              <td
                class="bt"
                style="font-weight: bold; color:#0e5499;"
              >
                <b v-if="data.contactDate != null">{{
                  formatDate(data.contactDate.substr(0, 10))
                }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 bg-light border border-dark border-padding"
        style="margin-bottom:3px"
      >
        <table class="table-sm table table-border m-0 pt-1">
          <tbody>
            <tr>
              <td colspan="1" style="font-weight: bold;padding-top:12px;">
                Corp. Name:
              </td>
              <td
                colspan="2"
                style="font-weight: bold; color:#0e5499; text-align:center;"
              >
                <h4 style="padding-top:3px;">{{ data.name }}</h4>
              </td>
              <td colspan="1" style="font-weight: bold;padding-top:12px;">
                Office:
              </td>
              <td
                colspan="2"
                style="font-weight: bold; color:#0e5499; text-align:center;"
              >
                <h4 style="padding-top:3px;">{{ data.office.office_name }}</h4>
              </td>
            </tr>
            <tr>
              <td class="bt" style="font-weight: bold;">
                DBA:
              </td>
              <td
                class="bt"
                style="font-weight: bold; color:#0e5499; text-align:center;"
              >
                <b>{{ data.dba }}</b>
              </td>
              <td class="bt" style="font-weight: bold">Representative:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                <b v-if="data.representative != null">{{ data.representative.name }}</b>
              </td>
            </tr>
            <tr>
              <td class="bt" colspan="1" style="font-weight: bold;">
                Business Address:
              </td>
              <td
                class="bt"
                colspan="5"
                style="font-weight: bold; color:#0e5499"
              >
                {{ data.address }}
              </td>
            </tr>
            <tr>
              <td class="bt" style="font-weight: bold">City:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                <b v-if="data.city != null">{{ data.city.name }}</b>
              </td>
              <td class="bt" style="font-weight: bold">State:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                <b v-if="data.state != null">{{ data.state.name }}</b>
              </td>
              <td class="bt" style="font-weight: bold">Zip:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                {{ data.zip }}
              </td>
            </tr>
            <tr>
              <td class="bt" style="font-weight: bold">Business Phone:</td>
              <td
                class="bt"
                colspan="3"
                style="font-weight: bold; color:#0e5499"
              >
                {{ data.phone }}
              </td>
              <td class="bt" colspan="1" style="font-weight: bold">Fax:</td>
              <td
                class="bt"
                colspan="1"
                style="font-weight: bold; color:#0e5499"
              >
                {{ data.fax }}
              </td>
            </tr>
            <tr>
              <td class="bt" colspan="2" style="font-weight: bold">
                Business Classification:
              </td>
              <td
                class="bt"
                colspan="4"
                style="font-weight: bold; color:#0e5499;"
              >
                <b v-if="data.clasification != null">{{
                  data.clasification.name
                }}</b>
              </td>
            </tr>
            <tr>
              <td class="bt" style="font-weight: bold; padding-top:12px;">
                Cert. of Authority:
              </td>
              <td
                class="bt"
                colspan="3"
                style="font-weight: bold; color:#0e5499; padding-top:12px;"
              >
                {{ data.certification }}
              </td>
              <td
                class="bt"
                colspan="1"
                style="font-weight: bold; padding-top:12px;"
              >
                Federal Id:
              </td>
              <td
                class="bt"
                colspan="1"
                style="font-weight: bold; color:#0e5499;"
              >
                <h6 style="padding-top:3px; font-weight: bold">
                  {{ data.federal }}
                </h6>
              </td>
            </tr>
            <tr>
              <td class="bt" style="font-weight: bold; width:16.6%">
                Business Started:
              </td>
              <td
                class="bt"
                style="font-weight: bold; color:#0e5499; width:16.6%"
              >
                <b v-if="data.started != null">{{
                  formatDate(data.started.substr(0, 10))
                }}</b>
              </td>
              <td class="bt" style="font-weight: bold; width:16.6%">
                Date of Incorporate:
              </td>
              <td
                class="bt"
                style="font-weight: bold; color:#0e5499; width:16.6%"
              >
                <b v-if="data.incorporate != null">{{
                  formatDate(data.incorporate.substr(0, 10))
                }}</b>
              </td>
              <td class="bt" style="font-weight: bold; width:16.6%">
                Fiscal Year:
              </td>
              <td
                class="bt"
                style="font-weight: bold; color:#0e5499; width:16.6%"
              >
                {{ data.fiscalYear }}
              </td>
            </tr>
            <tr>
              <td class="bt" style="font-weight: bold;">
                Status:
              </td>
              <td
                class="bt"
                style="font-weight: bold; color:#0e5499;"
              >
                <b>{{
                  data.status?'Active':'Inactive'
                }}</b>
              </td>
              <td class="bt" style="font-weight: bold;">
                Active Date:
              </td>
              <td
                class="bt"
                style="font-weight: bold; color:#0e5499;"
              >
                <b v-if="data.activeDate != null">{{
                  formatDate(data.activeDate.substr(0, 10))
                }}</b>
              </td>
              <td class="bt" style="font-weight: bold;">
                Inactive Date:
              </td>
              <td
                class="bt"
                style="font-weight: bold; color:#0e5499;"
              >
                <b v-if="data.inactiveDate != null">{{
                  formatDate(data.inactiveDate.substr(0, 10))
                }}</b>
              </td>
            </tr>
            <tr>
              <td class="bt" style="font-weight: bold;">
                Observations:
              </td>
              <td
                class="bt"
                colspan="5"
                style="font-weight: bold; color:#0e5499;"
              >
                {{data.observations}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <h4>Shareholders</h4>
      <div
        v-bind:key="shareholder.id"
        v-for="shareholder in data.shareholders"
        class="col-12 bg-light border border-dark border-padding"
        style="margin-bottom:3px"
      >
        <table class="table-sm table table-border m-0 pt-1">
          <tbody>
            <tr>
              <td style="font-weight: bold; width:16.6%">Name:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                {{ shareholder.name }}
              </td>
              <td style="font-weight: bold; width:16.6%">Last Name:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                {{ shareholder.lastName }}
              </td>
              <td style="font-weight: bold; width:16.6%">SS:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                {{ shareholder.securitySocial }}
              </td>
            </tr>
            <tr>
              <td style="font-weight: bold; width:16.6%">Title:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                <b v-if="shareholder.title != null">{{
                  shareholder.title.name
                }}</b>
              </td>
              <td class="bt" style="font-weight: bold">Home Address:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                {{ shareholder.address }}
              </td>
              <td class="bt" style="font-weight: bold">Zip:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                {{ shareholder.zip }}
              </td>
            </tr>
            <tr>
              <td class="bt" style="font-weight: bold">City:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                <b v-if="shareholder.city != null">{{
                  shareholder.city.name
                }}</b>
              </td>
              <td class="bt" style="font-weight: bold">State:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                <b v-if="shareholder.city != null">{{
                  shareholder.city.state.name
                }}</b>
              </td>
              <td class="bt" style="font-weight: bold">Shares:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                {{ shareholder.pivot.share }}%
              </td>
            </tr>
            <tr>
              <td class="bt" style="font-weight: bold">Phone:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                {{ shareholder.phone }}
              </td>
              <td class="bt" style="font-weight: bold">Date of Birth:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                <b v-if="shareholder.birthDay != null">{{
                  formatDate(shareholder.birthDay.substr(0, 10))
                }}</b>
              </td>
              <td class="bt" style="font-weight: bold">Language:</td>
              <td
                class="bt"
                style="font-weight: bold; color:#0e5499"
              >
                {{ shareholder.languaje }}
              </td>
            </tr>
            <tr>
              <td class="bt" style="font-weight: bold">Email:</td>
              <td
                class="bt"
                colspan="6"
                style="font-weight: bold; color:#0e5499"
              >
                {{ shareholder.email }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <h4>Former Owner</h4>
      <div
        class="col-12 bg-light border border-dark border-padding"
        style="margin-bottom:3px"
      >
        <table
          class="table-sm table table-border m-0 pt-1"
          v-if="data.formerowner"
        >
          <tbody>
            <tr>
              <td style="font-weight: bold; width:16.6%">Former Owner:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                {{ data.formerowner.name }}
              </td>
              <td style="font-weight: bold; width:16.6%">Phone:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                {{ data.formerowner.phone }}
              </td>
              <td style="font-weight: bold; width:16.6%">Fax:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                {{ data.formerowner.mobile }}
              </td>
            </tr>
            <tr>
              <td class="bt" style="font-weight: bold">Address:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                {{ data.formerowner.address }}
              </td>
              <td class="bt" style="font-weight: bold">City:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                <b v-if="data.formerowner.city != null">{{
                  data.formerowner.city.name
                }}</b>
              </td>
              <td class="bt" style="font-weight: bold">State/Zip:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                <b v-if="data.formerowner.city != null"
                  >{{ data.formerowner.city.state.name }} /
                  {{ data.formerowner.zip }}</b
                >
              </td>
            </tr>
            <tr>
              <td class="bt" style="font-weight: bold">Email:</td>
              <td
                class="bt"
                colspan="3"
                style="font-weight: bold; color:#0e5499"
              >
                {{ data.formerowner.email }}
              </td>
              <td class="bt" style="font-weight: bold">Note:</td>
              <td
                class="bt"
                colspan="2"
                style="font-weight: bold; color:#0e5499"
              >
                {{ data.formerowner.note }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <h4>Landlord</h4>
      <div
        class="col-12 bg-light border border-dark border-padding"
        style="margin-bottom:3px"
      >
        <table
          class="table-sm table table-border m-0 pt-1"
          v-if="data.landlord"
        >
          <tbody>
            <tr>
              <td style="font-weight: bold; width:16.6%">LandLord:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                {{ data.landlord.name }}
              </td>
              <td style="font-weight: bold; width:16.6%">Phone:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                {{ data.landlord.phone }}
              </td>
              <td style="font-weight: bold; width:16.6%">Fax:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                {{ data.landlord.mobile }}
              </td>
            </tr>
            <tr>
              <td class="bt" style="font-weight: bold">Address:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                {{ data.landlord.address }}
              </td>
              <td class="bt" style="font-weight: bold">City:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                <b v-if="data.landlord.city != null">{{
                  data.landlord.city.name
                }}</b>
              </td>
              <td class="bt" style="font-weight: bold">State/Zip:</td>
              <td class="bt" style="font-weight: bold; color:#0e5499">
                <b v-if="data.landlord.city != null"
                  >{{ data.landlord.city.state.name }} /
                  {{ data.landlord.zip }}</b
                >
              </td>
            </tr>
            <tr>
              <td class="bt" style="font-weight: bold">Email:</td>
              <td
                class="bt"
                colspan="3"
                style="font-weight: bold; color:#0e5499"
              >
                {{ data.landlord.email }}
              </td>
              <td class="bt" style="font-weight: bold">Note:</td>
              <td
                class="bt"
                colspan="2"
                style="font-weight: bold; color:#0e5499"
              >
                {{ data.landlord.note }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <h4>Bank Credentials</h4>
      <div
        v-bind:key="bank.id"
        v-for="bank in data.credential_banks"
        class="col-12 bg-light border border-dark border-padding"
        style="margin-bottom:3px"
      >
        <table class="table-sm table table-border m-0 pt-1">
          <tbody>
            <tr>
              <td style="font-weight: bold; width:16.6%">Bank:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                {{ bank.bank?bank.bank.name:'' }}
              </td>
              <td style="font-weight: bold; width:16.6%">Link:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                {{ bank.link }}
              </td>
              <td style="font-weight: bold; width:16.6%">Type:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                <b>{{ bank.type?bank.type.name:'' }}</b>
              </td>
            </tr>
            <tr>
              <td style="font-weight: bold;">User:</td>
              <td style="font-weight: bold; color:#0e5499;">
                {{ bank.user }}
              </td>
              <td style="font-weight: bold;">Password:</td>
              <td style="font-weight: bold; color:#0e5499;">
                {{ bank.password }}
              </td>
              <td style="font-weight: bold;">Routing:</td>
              <td style="font-weight: bold; color:#0e5499;">
                <b>{{ bank.routing }}</b>
              </td>
            </tr>
            <tr>
              <td colspan="1" style="font-weight: bold;">Account:</td>
              <td colspan="2" style="font-weight: bold; color:#0e5499;">
                {{ bank.account }}
              </td>
              <td colspan="1" style="font-weight: bold;">Notes:</td>
              <td colspan="2" style="font-weight: bold; color:#0e5499;">
                {{ bank.notes }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <h4>Credentials</h4>
      <div
        v-bind:key="credential.id"
        v-for="credential in data.credentials"
        class="col-12 bg-light border border-dark border-padding"
        style="margin-bottom:3px"
      >
        <table class="table-sm table table-border m-0 pt-1">
          <tbody>
            <tr>
              <td style="font-weight: bold;">Description:</td>
              <td colspan="2" style="font-weight: bold; color:#0e5499;">
                {{ credential.input?credential.input.name:'' }}
              </td>
              <td style="font-weight: bold;">Link:</td>
              <td colspan="2" style="font-weight: bold; color:#0e5499;">
                {{ credential.link }}
              </td>
            </tr>
            <tr>
              <td style="font-weight: bold; width:16.6%">User:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                {{ credential.user }}
              </td>
              <td style="font-weight: bold; width:16.6%">Password:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                {{ credential.password }}
              </td>
              <td style="font-weight: bold; width:16.6%">pin:</td>
              <td style="font-weight: bold; color:#0e5499; width:16.6%">
                <b>{{ credential.pin }}</b>
              </td>
            </tr>
            <tr>
              <td colspan="1" style="font-weight: bold;">Notes:</td>
              <td colspan="5" style="font-weight: bold; color:#0e5499;">
                {{ credential.notes }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import corporate from "@/services/api/corporate";
export default {
  name: "corporate-view",
  props: ["client"],
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    corporate.getPdf(this.client.uuid).then((response) => {
      this.data = response;
    });
  },
  watch: {
    client: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        corporate.getPdf(this.client.uuid).then((response) => {
          this.data = response;
        });
      },
    },
  },
  methods: {
    formatDate(datestr) {
      if (datestr !== "" && datestr !== null) {
        const date = new Date(datestr);
        date.setDate(date.getDate() + 1);
        const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        const [
          { value: month },
          ,
          { value: day },
          ,
          { value: year },
        ] = dateTimeFormat.formatToParts(date);

        return `${month}/${day}/${year}`;
      } else {
        return null;
      }
    },
  },
};
</script>

<style></style>
